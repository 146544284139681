import * as React from 'react';

import { Card as NextCard, CardHeader, CardFooter, Image, Button } from '@nextui-org/react';
import { Link as NavLink } from 'react-router-dom';

interface CardProps {
  className?: string;
  imgSrc: any;
  cardTitle: React.ReactNode;
  cardSubTitle: React.ReactNode;
  cardContent?: React.ReactNode;
  cardButtonText?: React.ReactNode;
  cardButtonLink: string;
}

export const Card: React.FunctionComponent<CardProps> = (props) => {
  const { className, imgSrc, cardTitle, cardSubTitle, cardButtonText, cardButtonLink } = props;

  return (
    <NextCard shadow={'none'} className={className + ' h-[300px]'}>
      <CardHeader className='absolute z-10 flex-col items-start'>
        <p className='mb-3 text-tiny uppercase font-bold'>{cardSubTitle}</p>
        <h4 className='font-medium text-xl'>{cardTitle}</h4>
      </CardHeader>

      <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={imgSrc} />

      <CardFooter className='absolute flex-row-reverse bottom-0 z-10'>
        <Button size={'sm'} variant={'solid'} color={`default`} as={NavLink} to={{ pathname: cardButtonLink }}>
          {cardButtonText}
        </Button>
      </CardFooter>
    </NextCard>
  );
};
