import * as React from 'react';
import { Link as NavLink } from 'react-router-dom';

import { Button } from '@nextui-org/react';
import { AnimatePresence, motion } from 'framer-motion';

import { HiQuestionMarkCircle } from 'react-icons/hi';

import { Card } from '../../common/components/card';
import { title, subtitle } from '../../common/components/title';
import { headContainerAnimation, headContentAnimation, headTextAnimation, slideAnimation } from '../../common/config/motion';

import renovationImage from '../../common/assets/images/douche.jpeg';
import maconnerieImage from '../../common/assets/images/trottoir.jpeg';

export const Home: React.FunctionComponent = () => {
  return (
    <AnimatePresence>
      <motion.section {...slideAnimation('left')} className='flex flex-col items-center justify-center'>
        <section
          className={'flex relative overflow-hidden lg:overflow-visible w-full flex-nowrap justify-between items-center h-[calc(84vh_-_64px)]'}
        >
          <motion.div {...headContainerAnimation} className={'flex relative z-20 flex-col gap-6 w-full xl:mt-10'}>
            <motion.div {...headTextAnimation} className={'text-center leading-8 md:leading-10 md:text-left'}>
              <h1 className={title({ size: 'lg' })}>InOutRenov</h1>
            </motion.div>

            <motion.h4 {...headContentAnimation} className={'md:text-left text-center ' + subtitle({ size: 'md' })}>
              Fort de 30 ans d'expérience et actif depuis 15 ans en tant qu'indépendant, je suis votre expert en rénovation et maçonnerie, engagé à
              concrétiser vos projets avec rigueur.
              <br />
              <br />
              Collaborant avec des partenaires de confiance - menuisier, plombier, électricien, peintre - je vous assure un respect des délais et un
              suivi méticuleux jusqu'à l'achèvement parfait de vos travaux.
              <br />
              <br />
              Votre satisfaction, ma priorité.
            </motion.h4>

            <div className={'animate-pulse flex flex-col md:flex-row items-center gap-4'}>
              <Button variant={'flat'} color={'warning'} radius={'full'} as={NavLink} to={{ pathname: '/prestations' }}>
                Prestations
              </Button>
            </div>
          </motion.div>
        </section>

        <section className={'flex flex-col w-full'}>
          <Card
            imgSrc={renovationImage}
            className={'w-full md:w-2/3 self-end mb-20 md:mb-40 text-white'}
            cardSubTitle={<span className={'bg-gray-950/75 ' + title({ size: 'sm' })}>Rénovation</span>}
            cardTitle={
              <span className={'bg-gray-950/75 ' + title({ size: 'sm' })}>Offrez-vous un intérieur qui allie esthétique et fonctionnalité</span>
            }
            cardButtonText={'EN SAVOIR PLUS'}
            cardButtonLink={'/renovation'}
          />

          <Card
            imgSrc={maconnerieImage}
            className={'w-full md:w-2/3 self-start mb-20 md:mb-40 text-white'}
            cardSubTitle={<span className={'bg-gray-950/75 ' + title({ size: 'sm' })}>Maçonnerie</span>}
            cardTitle={<span className={'bg-gray-950/75 ' + title({ size: 'sm' })}>Posez des fondations solides pour vos espaces extérieurs</span>}
            cardButtonText={'EN SAVOIR PLUS'}
            cardButtonLink={'/maçonnerie'}
          />
        </section>
      </motion.section>
    </AnimatePresence>
  );
};
