import { tv } from 'tailwind-variants';

export const title = tv({
  base: 'tracking-tight inline font-semibold',
  variants: {
    size: {
      xxs: 'text-lg sm:text-lg md:text-xl lg:text-xl',
      sm: 'text-2xl sm:text-2xl md:text-3xl lg:text-3xl',
      md: 'text-3xl sm:text-3xl md:text-4xl lg:text-4xl',
      lg: 'text-4xl sm:text-4xl md:text-5xl lg:text-5xl',
    },
    fullWidth: {
      true: 'w-full',
    },
    dropShadow: {
      true: 'drop-shadow-md',
    },
  },
  defaultVariants: {
    size: 'md',
    fullWidth: true,
    dropShadow: false,
  },
});

export const subtitle = tv({
  base: 'font-normal text-default-500',
  variants: {
    color: {
      violet: 'from-[#FF1CF7] to-[#b249f8]',
      yellow: 'from-[#FF705B] to-[#FFB457]',
      blue: 'from-[#5EA2EF] to-[#0072F5]',
      cyan: 'from-[#00b7fa] to-[#01cfea]',
      green: 'from-[#6FEE8D] to-[#17c964]',
      pink: 'from-[#FF72E1] to-[#F54C7A]',
      foreground: 'dark:from-[#FFFFFF] dark:to-[#4B4B4B]',
    },
    size: {
      sm: 'text-md sm:text-md md:text-lg lg:text-lg',
      md: 'text-lg sm:text-lg md:text-xl lg:text-xl',
      lg: 'text-xl sm:text-xl md:text-1xl lg:text-1xl',
    },
    fullWidth: {
      true: 'w-full',
    },
  },
  defaultVariants: {
    size: 'md',
    fullWidth: true,
  },
});
