import * as React from 'react';
import { useLocation, useRoutes } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';

import { Home } from '../features/home/Home';
import { Prestations } from '../features/prestations';
import { Renovation } from '../features/renovation/Renovation';
import { Maconnerie } from '../features/maconnerie/Maconnerie';

import { NotFound } from '../common/components/404';

interface RouteProps {
  label: string;
  path: string;
  element: React.ReactNode | null;
}

export const routesData: Array<RouteProps> = [
  {
    label: 'Accueil',
    path: '/',
    element: <Home />,
  },
  {
    label: 'Rénovation',
    path: '/renovation',
    element: <Renovation />,
  },
  {
    label: 'Maçonnerie',
    path: '/maçonnerie',
    element: <Maconnerie />,
  },
  {
    label: 'Prestations',
    path: '/prestations',
    element: <Prestations />,
  },
];

export const Routes: React.FunctionComponent = () => {
  const location = useLocation();
  const routes = useRoutes(routesData);

  if (!routes) {
    return <NotFound />;
  }

  return (
    <main className={'mx-auto max-w-7xl px-12 flex-grow'}>
      <AnimatePresence mode='wait'>{React.cloneElement(routes, { key: location.pathname })}</AnimatePresence>
    </main>
  );
};
