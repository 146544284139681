import * as React from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { title, subtitle } from '../../common/components/title';

export const Renovation: React.FunctionComponent = () => {
  return (
    <AnimatePresence>
      <section className='flex flex-col items-center justify-center'>
        <section className={'relative z-10 flex flex-col gap-2 w-full mt-24 lg:mt-24'}>
          <motion.div
            className={'text-center'}
            exit={{ opacity: 0, x: 90 }}
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: -90 }}
            transition={{
              delay: 0,
              duration: 1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <h1 className={title({ size: 'lg' })}>Rénovation&nbsp;</h1>

            <br />
            <br />

            <motion.h1
              className={title({ size: 'sm' })}
              exit={{ opacity: 0, y: -90 }}
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 90 }}
              transition={{
                delay: 0.25,
                duration: 1.5,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              Offrez-vous un intérieur qui allie esthétique et fonctionnalité. Nos services sur mesure sont conçus pour répondre à chaque aspect de
              votre projet de rénovation :
            </motion.h1>
          </motion.div>

          <motion.div
            className={'text-center'}
            exit={{ opacity: 0, y: 60 }}
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: -60 }}
            transition={{
              delay: 1,
              duration: 1.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <h4 className={subtitle({ class: 'mt-6' })}>
              Sol : Découvrez la beauté et la durabilité à travers notre sélection de carrelages et faïences, choisis pour embellir chaque pièce.
              <br />
              <br />
              Plâtrerie : Bénéficiez de nos solutions en placo, pour accueillir vos idées déco.
              <br />
              <br />
              Salle de Bain : Transformez votre salle d'eau en un havre de paix, avec des designs qui marient innovation et confort.
              <br />
              <br />
              Ouvertures : Illuminez votre espace de vie avec des créations et modifications d'ouvertures qui invitent la lumière naturelle.
              <br />
              <br />
              Home Staging et Cheminées : Augmentez la valeur de votre bien avec des touches décoratives raffinées et des cheminées qui créent une
              ambiance chaleureuse.
              <br />
              <br />
              Plâtrerie : Bénéficiez de finitions impeccables avec nos solutions en placo.
              <br />
              <br />
              Parquet : Introduisez l'élégance et la chaleur du bois avec les parquets de votre choix.
            </h4>
          </motion.div>
        </section>
      </section>
    </AnimatePresence>
  );
};
