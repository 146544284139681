import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Root, createRoot } from 'react-dom/client';

import { NextUIProvider } from '@nextui-org/react';

import { Routes } from './routes';

import { Navbar } from './common/components/navbar';
import { Footer } from './common/components/footer';

import './index.css';

let root: Root | HTMLElement | null;
const container = document.getElementById('root');

if (!container) {
  throw Error('unable to find root element');
} else {
  root = createRoot(container);
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <NextUIProvider className={'relative flex flex-col'}>
        <main className='light text-foreground bg-background'>
          <Navbar />
          <Routes />

          <Footer />
        </main>
      </NextUIProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
