import * as React from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { title, subtitle } from '../../common/components/title';

export const Maconnerie: React.FunctionComponent = () => {
  return (
    <AnimatePresence>
      <section className='flex flex-col items-center justify-center'>
        <section className={'relative z-10 flex flex-col gap-2 w-full mt-24 lg:mt-24'}>
          <motion.div
            className={'text-center'}
            exit={{ opacity: 0, x: 90 }}
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: -90 }}
            transition={{
              delay: 0,
              duration: 1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <h1 className={title({ size: 'lg' })}>Maçonnerie&nbsp;</h1>

            <br />
            <br />

            <motion.h1
              className={title({ size: 'sm' })}
              exit={{ opacity: 0, y: -90 }}
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 90 }}
              transition={{
                delay: 0.25,
                duration: 1.5,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              Posez des fondations solides pour vos espaces extérieurs. Nos compétences spécialisées vous garantissent esthétique et robustesse :
            </motion.h1>
          </motion.div>

          <motion.div
            className={'text-center'}
            exit={{ opacity: 0, y: 60 }}
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: -60 }}
            transition={{
              delay: 1,
              duration: 1.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <h4 className={subtitle({ class: 'mt-6' })}>
              Chape et Dallage : Créez une base solide et esthétique pour tous vos espaces, avec des matériaux choisis pour leur longévité.
              <br />
              <br />
              Terrasses : Évadez-vous dans votre jardin grâce à nos terrasses personnalisées en bois, béton ou carrelage, conçues pour vos moments de
              détente.
              <br />
              <br />
              Clôtures et Murets : Encadrez votre espace avec style grâce à nos solutions de clôtures et murets, combinant sécurité et esthétique.
              <br />
              <br />
              Piliers de Portail : Accueillez vos invités avec élégance grâce à des piliers sur mesure, conçus pour compléter l'esthétique de votre
              entrée.
              <br />
              <br />
              Aménagement de Garage : Maximisez votre espace en transformant votre garage en une pièce à vivre pratique et accueillante.
            </h4>
          </motion.div>
        </section>
      </section>
    </AnimatePresence>
  );
};
