import * as React from 'react';

import { Button, Image } from '@nextui-org/react';
import { Carousel } from 'react-responsive-carousel';
import { AnimatePresence, motion } from 'framer-motion';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { title } from '../../common/components/title';

import img1 from '../../common/assets/images/IMG-20220725-WA0005.jpg';
import img2 from '../../common/assets/images/20180314_084820.jpg';
import img3 from '../../common/assets/images/20180325_140510.jpg';
import img4 from '../../common/assets/images/20180331_183006.jpg';
import img5 from '../../common/assets/images/20180331_183033.jpg';
import img6 from '../../common/assets/images/20181026_151049.jpg';
import img7 from '../../common/assets/images/PHOTO-2024-02-13-17-31-08.jpg';
import img8 from '../../common/assets/images/PHOTO-2024-02-13-17-31-18.jpg';
import img9 from '../../common/assets/images/PHOTO-2024-02-13-17-31-28.jpg';
import img10 from '../../common/assets/images/PHOTO-2024-02-13-17-31-38.jpg';
import img11 from '../../common/assets/images/PHOTO-2024-02-13-17-31-47.jpg';
import img12 from '../../common/assets/images/PHOTO-2024-02-13-17-32-04.jpg';
import img13 from '../../common/assets/images/20181123_144239.jpg';

export const Prestations: React.FunctionComponent = () => {
  return (
    <AnimatePresence>
      <section className='flex flex-col items-center justify-center'>
        <section className={'relative z-10 flex flex-col gap-2 w-full mt-24 lg:mt-24'}>
          <motion.div
            className={'text-center mb-10'}
            exit={{ opacity: 0, x: 90 }}
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: -90 }}
            transition={{
              delay: 0,
              duration: 1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <h1 className={title({ size: 'lg' })}>Prestations&nbsp;</h1>
          </motion.div>

          <Carousel autoPlay infiniteLoop>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img1} />
            </div>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img2} />
            </div>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img3} />
            </div>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img4} />
            </div>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img5} />
            </div>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img6} />
            </div>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img7} />
            </div>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img8} />
            </div>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img9} />
            </div>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img10} />
            </div>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img11} />
            </div>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img12} />
            </div>
            <div>
              <Image removeWrapper alt='prestation' className='z-0 w-full h-full object-cover' src={img13} />
            </div>
          </Carousel>
        </section>

        <Button variant={'flat'} color={'warning'} radius={'full'} as={'a'} href={'https://instagram.com/inoutrenov'} target={'_blank'}>
          Plus de photos
        </Button>
      </section>
    </AnimatePresence>
  );
};
