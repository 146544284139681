import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@nextui-org/react';
import { AnimatePresence, motion } from 'framer-motion';

import { title, subtitle } from '../title';

export const NotFound: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <section className='flex w-full items-center justify-center px-6 py-10'>
      <div className='inline-block px-6 w-full relative max-w-[1280px] text-left justify-left'>
        <AnimatePresence>
          <motion.div
            exit={{ opacity: 0, x: 90 }}
            animate={{ opacity: 1, x: 1 }}
            initial={{ opacity: 0, x: -90 }}
            transition={{
              duration: 1.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <h1 className={title({ size: 'lg' })}>Oups,&nbsp;</h1>
            <h1 className={title({ size: 'lg' })}>404&nbsp;</h1>
            <h1 className={title({ size: 'lg' })}>error.</h1>
          </motion.div>
        </AnimatePresence>

        <AnimatePresence>
          <motion.div
            exit={{ opacity: 0, x: -60 }}
            animate={{ opacity: 1, x: 1 }}
            initial={{ opacity: 0, x: 60 }}
            transition={{
              delay: 0.5,
              duration: 1.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <h4 className={subtitle({ class: 'mt-6' })}>Page not found. Contact the owner if the problem persist.</h4>
          </motion.div>
        </AnimatePresence>

        <motion.div
          className={'flex flex-row-reverse mt-6 gap-4 relative flex-nowrap items-center'}
          exit={{ opacity: 0, y: -60 }}
          animate={{ opacity: 1, y: 1 }}
          initial={{ opacity: 0, y: 60 }}
          transition={{
            delay: 1.1,
            duration: 1.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <Button onClick={() => navigate('/')} color={'primary'} variant={'light'}>
            home
          </Button>

          <Button onClick={() => navigate(-1)} color={'secondary'} variant={'flat'}>
            back
          </Button>
        </motion.div>
      </div>
    </section>
  );
};
